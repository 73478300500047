
// Mixins
// --------------------------------------
@mixin animation-variant($duration, $timing-function){
    &{
        #navbar,
        #content-container,
        #mainnav-container,
        #aside-container,
        #footer,
        .collapsing,
        .navbar-brand,
        .navbar-content,
        .navbar-header:before,
        .page-fixedbar-container{
            transition-duration: $duration;
            transition-timing-function: $timing-function;
        }
    }
}

.collapsing {
    transition-duration: 10ms;
}


/* ANIMATIONS */
/* -------------------------------------*/
@if $enable-animation == true{
    @media (min-width: 768px){
        .effect{
            .mainnav-profile{
                transition: opacity $ease-duration, max-height $ease-duration + .2s;
            }
        }
    }
    .effect{
        #navbar,
        #content-container,
        #mainnav-container,
        #aside-container,
        #footer,
        .navbar-brand,
        .navbar-content,
        .navbar-header:before,
        .page-fixedbar-container{
            transition-property: width, padding, left, right;
            transition-duration: $ease-duration
        }
        .navbar-content{
            transition-property: margin-left;
        }
        .collapsing {
            transition-duration: .4s;
        }
    }


    /* Variant */
    /* -------------------------------------*/
    @if $enable-easeInQuart == true{
        .easeInQuart{@include animation-variant($easeInQuart, cubic-bezier(0.755, 0.045, 0.915, 0.135))}
    }
    @if $enable-easeOutQuart == true{
        .easeOutQuart{@include animation-variant($easeOutQuart, cubic-bezier(0.015, 0.770, 0.040, 0.985))}
    }
    @if $enable-easeInBack == true{
        .easeInBack{@include animation-variant($easeInBack, cubic-bezier(0.705, -0.155, 0.735, 0.045))}
    }
    @if $enable-easeOutBack == true{
        .easeOutBack{@include animation-variant($easeOutBack, cubic-bezier(0.280, 1.020, 0.360, 1.145))}
    }
    @if $enable-easeInOutBack == true{
        .easeInOutBack{@include animation-variant($easeInOutBack, cubic-bezier(1.000, -0.280, 0.000, 1.275))}
    }
    @if $enable-steps == true{
        .steps{@include animation-variant($steps, cubic-bezier(0.000, 0.955, 1.000, 0.045))}
    }
    @if $enable-jumping == true{
        .jumping{@include animation-variant($jumping, cubic-bezier(0.135, 1.525, 0.000, 0.900))}
    }
    @if $enable-rubber == true{
        .rubber{@include animation-variant($rubber, cubic-bezier(0.000, 1.650, 1.000, -0.600))}
    }

}
