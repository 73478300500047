

/* BOOTSTRAP'S COMPONENTS */
/*======================================*/
@use "sass:math";

.collapse{
    display: none
}

.jumbotron{
    padding: 30px;
    background-color: darken($body-bg, 5%)
}

.navbar-toggle .icon-bar{
    background-color: #aaaaaa
}

.canvas-responsive{
    max-width: 100%
}



a{
    text-decoration: none;
    color: $link-color;
    outline: 0;
    &:hover,
    &:focus{
        text-decoration: none;
        color: $link-hover-color;
        outline: 0 !important
    }
}
button, button:focus{
    outline: 0 !important
}
code{
    background-color: #e7e3f2;
    color: #ff0000;
    padding: 2px 7px;
    border-radius: $border-radius-base;
    font-size: 97%
}
kbd {
    border-radius: 2px;
    box-shadow: none;
}
label{
    font-weight: normal
}
legend{
    padding: 10px;
    font-size: 18px;
    font-weight: $font-semibold;
    border-color: #eee;
}
mark, .mark{
    background-color: #ffe3a2;
    color: #563c00;
    padding: .1em
}

.close{
    font-size: 15px;
    color: $body-main-color;
    opacity: 1;
    text-shadow: none;
    &:focus, &:hover{
        color: $body-main-color;
        opacity: .7;
    }
}


/* HORIZONTAL RULE */
/*======================================*/
hr{
    border-color: $border-color-base;
}
.hr{
    &-wide{
        margin-left: 0 - ($grid-gutter-width*0.5);
        margin-right: 0 - ($grid-gutter-width*0.5);
    }
    &-xs{
        margin: 5px 0
    }
    &-sm{
        margin: 10px 0
    }
}

.new-section{
    &-xs{
        margin: 12px 0;
        min-height: 1px
    }
    &-sm{
        margin: 25px 0;
        min-height: 1px
    }
    &-md{
        margin: 50px 0;
        min-height: 1px
    }
    &-lg{
        margin: 100px 0;
        min-height: 1px
    }
    &-xl{
        margin: 150px 0;
        min-height: 1px
    }
}


/* GRID-GUTTER */
/*======================================*/
.row{
    margin : 0 (0 - ($grid-gutter-width*0.5));
}
[class^="col-"]:not(.pad-no){
    padding-left: ($grid-gutter-width*0.5);
    padding-right: ($grid-gutter-width*0.5);
}




/* MEDIA */
/*======================================*/
.media-block{
    .media-left{
        display: block;
        float: left;
    }
    .media-right{
        float: right;
    }
    .media-body{
        display: block;
        overflow: hidden;
        width: auto
    }
    &:after{
        content: '';
        display: table;
        clear: both
    }
}
.middle{
    .media-left,
    .media-right,
    .media-body{
        vertical-align: middle
    }
}





/* THUMBNAILS */
/*======================================*/
.thumbnail{
    background-color: rgba(255,255,255,.2);
    border-radius: $border-radius-base;
    border-color: $border-color-base;
}




/* TABLES */
/*======================================*/
@if $table == true {
    $border-color : $border-color-base;
    #container{
        .table{
            th{
                font-size: 1.05em;
                font-weight: $font-semibold;
                border-bottom: 1px solid $border-color;
                color: $body-main-color
            }
            td{
                border-top: 1px solid $border-color;
            }
            &.table-vcenter{
                th, td{
                    vertical-align: middle
                }
            }
            .min-width{
                width: 1%;
                white-space: nowrap;
                padding-left: 15px !important;
                padding-right: 15px !important
            }
        }
        .table-trans {
            tr, td, th{
                background-color: transparent !important;
                border-color: transparent !important;
            }
        }
        .table-bordered{
            &, td, th{
                border-color: $border-color;
            }
        }
        .table-striped > tbody > tr:nth-child(2n+1){
            background-color: rgba(0,0,0, .015);
        }
        .table-hover > tbody > tr:hover{
            background-color: rgba(0,0,0, .015);
        }
    }
}



 /* FORM CONTROL  */
/*======================================*/
@if $form-control == true {
    .form-control{
        height: 33px;
        font-size: $font-size-base;
        border-radius: $border-radius-base;
        box-shadow: none;
        border: 1px solid $border-color-base;
        transition-duration: .5s;
        &:focus{
            border-color: $state-active-bg;
            box-shadow: none;
            transition-duration: .5s;
            &-feedback{
                z-index: 10
            }
        }
        &-trans{
            border: 0 !important;
            background-color: transparent !important;
        }
    }


    .input-sm {
        font-size: floor(math.div($font-size-base, 1.16667));
        height: 30px;
    }
    .input-lg {
        font-size: ceil(math.div($font-size-base, 0.77777));
        height: 46px;
    }


    .has-error .form-control,
    .has-warning .form-control,
    .has-success .form-control{
        box-shadow: none !important
    }

    i.form-control-feedback{
        line-height: 25px
    }

    .input-group-addon{
        font-size: $font-size-base;
        border: 1px solid $border-color-base;
        background-color: #fff;
        color: $body-color;
        border-radius: $border-radius-base;
        min-width: 45px;
    }
    .input-group{
        .btn:not(.btn-lg):not(.btn-sm):not(.btn-xs){
            max-height: 33px
        }
    }
    .form-horizontal {
        .form-group{
            margin-left: 0 - ($grid-gutter-width*0.5);
            margin-right: 0 - ($grid-gutter-width*0.5);
        }
        .checkbox, .radio{
            min-height: 30px;
        }
    }
}

.has-warning{
    .form-control{
        border-color: lighten($warning-bg, 15%);
        &:focus{
            border-color: darken($warning-bg, 10%);
        }
    }

    .checkbox,
    .checkbox-inline,
    .control-label,
    .help-block,
    .radio,
    .radio-inline,
    &.checkbox label,
    &.checkbox-inline label,
    &.radio label,
    &.radio-inline label{
        color: darken($warning-bg, 5%);
        font-weight: 700
    }
}

.has-error{
    .form-control{
        border-color: lighten($danger-bg, 15%);
        &:focus{
            border-color: darken($danger-bg, 10%);
        }
    }

    .checkbox,
    .checkbox-inline,
    .control-label,
    .help-block,
    .radio,
    .radio-inline,
    &.checkbox label,
    &.checkbox-inline label,
    &.radio label,
    &.radio-inline label{
        color: lighten($danger-bg, 5%);
        font-weight: 700
    }
}

.has-success{
    .form-control{
        border-color: lighten($success-bg, 15%);
        &:focus{
            border-color: darken($success-bg, 10%);
        }
    }

    .checkbox,
    .checkbox-inline,
    .control-label,
    .help-block,
    .radio,
    .radio-inline,
    &.checkbox label,
    &.checkbox-inline label,
    &.radio label,
    &.radio-inline label{
        color: $success-bg;
        font-weight: 700
    }
}




/* NAV PILLS */
/*======================================*/
@if $nav-pills == true {
    .nav{
        &-pills{
            > li{
                > a{
                    border-radius: $border-radius-base;
                }
            }
            >.active{
                > a{
                    &, &:hover, &:focus{
                        background-color: $primary-bg;
                    }
                }
            }
        }
        &-tabs{
            >li{
                >a{
                    border-radius: $border-radius-base $border-radius-base 0 0
                }
            }
        }
    }
}










/* LIST GROUP */
/*======================================*/

// Mixin
// ----------------------------------------------
@mixin list-variant($color, $background){
    @at-root{
        @at-root a#{&}, &{
            background-color: lighten($background,7%);
            border-color: transparent;
            color: $color
        }
        @at-root a#{&}{
            &:hover, &:focus{
                background-color: lighten($background,10%);
                color: $color
            }
        }
    }
}


@if $list-group == true {
    .list-group{
        &-striped > li:nth-child(odd),
        &-striped > a:nth-child(odd):not(.active):not(.disabled) {
            background-color: rgba(0,0,0,0.015);
        }
        &.bg-trans{
            .list-group-item{
                &:not(.active):not(.disabled){
                    background-color: transparent;
                    border-color: transparent;
                    color: inherit
                }
                .disabled{
                    opacity: .5
                }
            }
            a.list-group-item:hover:not(.active){
                background-color: rgba(0,0,0,0.05);
            }
        }
        &.bord-no .list-group-item{
            border-color: transparent
        }
        .list-divider{
            display: block
        }

    }

    %list-group-item{
        color: $body-color;
    }

    %list-group-item-hover{
        background-color: rgba(0,0,0,.05);
        color: $body-color;
    }

    %list-group-item-heading{
        color: $body-main-color;
    }

    a.list-group-item, button.list-group-item{
        @extend %list-group-item;
        &:hover, &:focus{
            @extend %list-group-item-hover;
        }
        .list-group-item-heading{
            @extend %list-group-item-heading;
        }
    }

    .list-group-item{
        border-color: $border-color-base;
        background-color: transparent;
        @extend %list-group-item;
        &-heading {
            margin-top: 5px;
        }
        &:first-child {
            border-top-left-radius: $border-radius-base;
            border-top-right-radius: $border-radius-base;
        }
        &:last-child {
            border-bottom-left-radius: $border-radius-base;
            border-bottom-right-radius: $border-radius-base;
        }
        &.disabled{
            &, &:hover, &:focus{
                background-color: transparent;
                color: $text-muted;
            }
        }
        &.active{
            &, &:hover, &:focus{
                background-color: $state-active-bg !important;
                border-color: $state-active-bg;
                color: $state-active-color;
                .list-group-item-text{
                    color: $state-active-color;
                }
            }
        }
    }







    // List variant
    // ----------------------------------------------
    .list-group-item-primary{
        @include list-variant($primary-color, $primary-bg)
    }

    .list-group-item-info{
        @include list-variant($info-color, $info-bg)
    }

    .list-group-item-success{
        @include list-variant($success-color, $success-bg)
    }

    .list-group-item-warning{
        @include list-variant($warning-color, $warning-bg)
    }

    .list-group-item-danger{
        @include list-variant($danger-color, $danger-bg)
    }

    @if $enable-mint == true {
        .list-group-item-mint{
            @include list-variant($mint-color, $mint-bg)
        }
    }

    @if $enable-purple == true {
        .list-group-item-purple{
            @include list-variant($purple-color, $purple-bg)
        }
    }

    @if $enable-pink == true {
        .list-group-item-pink{
            @include list-variant($pink-color, $pink-bg)
        }
    }
    @if $enable-dark == true {
        .list-group-item-dark{
            @include list-variant($dark-color, $dark-bg)
        }
    }

}









/* LABEL */
/*======================================*/
@mixin label-variant($background){
    @at-root{
        &{
            background-color: $background;
        }
        &[href]:hover, &[href]:focus{
            background-color: darken($background, 5%)
        }
    }
}
@if $label == true {

    .label{
        border-radius: $border-radius-base;
        font-weight: $font-semibold;
        &:empty{
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            vertical-align: sub;
        }
        &.label-fw{
            margin-right: .5em
        }
        .labels &{
            display: inline-block;
            margin-right: 3px;
            margin-bottom: 3px
        }
        &-md{
            font-size: 100%;
        }
        &-table{
            display: inline-block;
            width: 80%;
            min-width: 8ex;
            font-size: 1em;
            max-width: 100px;
            padding: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            vertical-align: top
        }
    }


    // Label variant
    // ----------------------------------------------
    .label-default{
        background-color: #e3e8ee;
        color: #333;
        &[href]:hover, &[href]:focus{
            background-color: darken(#e3e8ee, 5%)
        }
    }
    .label-primary{
        @include label-variant($primary-bg);
    }
    .label-info{
        @include label-variant($info-bg);
    }
    .label-success{
        @include label-variant($success-bg);
    }
    .label-warning{
        @include label-variant($warning-bg);
    }
    .label-danger{
        @include label-variant($danger-bg);
    }
    @if $enable-mint == true {
        .label-mint{@include label-variant($mint-bg)}
    }
    @if $enable-purple == true {
        .label-purple{@include label-variant($purple-bg)}
    }
    @if $enable-pink == true {
        .label-pink{@include label-variant($pink-bg)}
    }
    @if $enable-dark == true {
        .label-dark{@include label-variant($dark-bg)}
    }
}




 /* BREADCRUMB */
/*======================================*/
@if $breadcrumb == true {
    .breadcrumb{
        border-radius: 0;
        background-color: transparent;
        margin-bottom: 0;
        padding: 0 $grid-gutter-width 10px;
        font-size: 1em;
        li{
            &, & a{
                color: $body-main-color;
            }
            & a:hover{
                color: $info-bg;
                text-decoration: underline
            }
        }
        >li{
            & + li:before{
                content: "";
                border-color: $body-main-color;
                border-style: solid;
                border-width: .1em .1em 0 0;
                display: inline-block;
                height: .55em;
                left: 0;
                position: relative;
                top: -.1em;
                width: .55em;
                padding: 0;
                margin-left: 1ex;
                margin-right: 1.7ex;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        .active{
            font-weight: 700;
            color: $body-main-color;
        }
    }
}




/* ALERTS */
/*======================================*/

// Mixin
// ----------------------------------------------
@mixin alert-variant($color, $background){
    background-color: desaturate(lighten($background, 7%), 7%);
    border-color: transparent;
    border-left: 3px solid desaturate(darken($background, 15%), 3%);
    color: $color;
    .close, .alert-link{
        color: $color
    }
}

@if $alert == true {
    .alert{
        border-radius: $border-radius-base;
        border: 0;
        padding: 15px 2em 15px 15px;
        position: relative;
        .alert-link{
            text-decoration: underline;
            font-weight: $font-semibold;
            &:hover{
                text-decoration: underline
            }
        }
    }


    .alert {
        .close{
            font-size: 12.5px;
            text-shadow: none;
            opacity: .7;
            position: absolute;
            left: auto;
            right: 10px;
            top: 10px;
        }
        button.close{
            padding: 1px;
            border-radius: 20px;
            @include transition(all, .3s);
            >span:not(.sr-only){
                display: block;
                width: 1em;
                height: 1em;
                line-height: .8em
            }
            &:hover{
                opacity: .55;
            }
            &:active{
                opacity: .3
            }
        }
    }



    // Alert variant
    // ----------------------------------------------
    .alert-primary{
        @include alert-variant($primary-color, $primary-bg)
    }
    .alert-info{
        @include alert-variant($info-color, desaturate($info-bg, 15%))
    }
    .alert-success{
        @include alert-variant($success-color, $success-bg)
    }
    .alert-warning{
        @include alert-variant($warning-color, $warning-bg)
    }
    .alert-danger{
        @include alert-variant($danger-color, $danger-bg)
    }

    @if $enable-mint == true {
        .alert-mint{@include alert-variant($mint-color, $mint-bg)}
    }
    @if $enable-purple == true {
        .alert-purple{@include alert-variant($purple-color, $purple-bg)}
    }
    @if $enable-pink == true {
        .alert-pink{@include alert-variant($pink-color, $pink-bg)}
    }
    @if $enable-dark == true {
        .alert-dark{@include alert-variant($dark-color, lighten($dark-bg, 10%))}
    }

}





/* MODALS */
/*======================================*/
@if $modal == true {
    .modal{
        text-align: center;
        z-index: 1000;
        &:before{
            content: '';
            display: inline-block;
            height: 100%;
            width: 0;
            margin-left: -1em;
            vertical-align: middle;
        }
        &.fade{
            &:not(.animated) .modal-dialog{
                opacity: 0;
                -webkit-transform : translateY(-150%);
                -ms-transform : translateY(-150%);
                transform : translateY(-150%);
                -webkit-transition: all .5s linear .5s;
                transition: all .5s linear .5s;
            }
            &.in:not(.animated) .modal-dialog{
                opacity: 1;
                -webkit-transform : translateY(0%);
                -ms-transform : translateY(0%);
                transform : translateY(0%);
                -webkit-transition: all .5s;
                transition: all .5s;
            }
        }
        @at-root .bootbox#{&}{
            &.in:not(.fade){
                opacity: 1;
                @include transition(opacity, .5s)
            }
            &:not(.fade){
                opacity: 0;
                -webkit-transition: opacity .5s linear .5s;
                transition: opacity .5s linear .5s;
            }
        }
        &-dialog{
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            margin-top: -1%;
            min-width: 90%;
            &.animated{
                -webkit-animation-duration: .7s;
                animation-duration: .7s;
            }
        }
        &-header{
            padding: 15px;
            position: relative;
            border: 0;
        }
        &-footer{
            background-color: transparent;
            color: $body-color;
            border-color: $border-color-base;
            border-top-left-radius: $border-radius-base;
            border-top-right-radius: $border-radius-base;
        }
        &-title{
            font-size: 1.2em;
            color: $body-main-color
        }
        &-body{
            min-height: 90px
        }
        &-body > .close, &-header > .close{
            top: 50%;
            margin-top: -.5em;
            right: 10px;
            left: auto;
            position: absolute;
            background-color: transparent !important
        }
        &-body > .close{
            top: 10px;
            margin-top: 0 !important
        }
        &-content{
            box-shadow: none;
            border-radius: $border-radius-base;
            border: 0
        }
        &-footer{
            padding: 10px 15px;
            border-bottom-left-radius: $border-radius-base;
            border-bottom-right-radius: $border-radius-base;
        }
        &-backdrop.in{
            opacity: 0.75
        }
    }


    .modal-backdrop{
        z-index: 999
    }

    @media (min-width: 768px){
        .modal-dialog{
            min-width: 0
        }
    }
}






 /* TOOLTIP */
/*======================================*/
@if $tooltip == true {
    .tooltip{
        z-index: 99999;
        &-inner{
            font-size: $font-size-base;
            border-radius: $border-radius-base;
            padding: 5px 10px;
            background-color: $tooltip-bg
        }
        &.top{
            .tooltip-arrow, &-left .tooltip-arrow, &-right .tooltip-arrow{
                border-top-color: $tooltip-bg;
            }
        }
        &.right{
            .tooltip-arrow{
                border-right-color: $tooltip-bg
            }
        }
        &.left{
            .tooltip-arrow{
                border-left-color: $tooltip-bg
            }
        }
        &.bottom{
            .tooltip-arrow, &-left .tooltip-arrow, &-right .tooltip-arrow{
                border-bottom-color: $tooltip-bg
            }
        }
        &.in{
            opacity: 1;
        }
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
            color: inherit
        }
    }
}





/* POPOVER */
/*======================================*/
@if $popover == true {
    .popover{
        font-family: inherit;
        font-size: $font-size-base;
        border-radius: $border-radius-base;
        border: 1px solid darken($body-bg, 1%);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        &-title{
            background-color: transparent;
            color: $body-main-color;
            font-size: 1.2em;
            font-weight: $font-semibold;
            border-bottom: 1px solid rgba(0, 0, 0, 0.03);
            border-radius: $border-radius-base $border-radius-base 0 0;
        }
        >.arrow{
            border-width: 9px;
            &:after{
                border-width: 9px;
            }
        }
        &.left{
            >.arrow{
                border-left-color: rgba(0, 0, 0, 0.07);
                right: -9px;
                margin-top: -9px;
                &:after{
                    bottom: -9px;
                }
            }
        }
        &.right{
            >.arrow{
                border-right-color: rgba(0, 0, 0, 0.07);
                left: -9px;
                margin-top: -9px;
                &:after{
                    bottom: -9px;
                }
            }
        }
        &.top{
            >.arrow{
                border-top-color: rgba(0, 0, 0, 0.07);
                bottom: -9px;
                &:after{
                    margin-left: -9px
                }
            }
        }
        &.bottom{
            >.arrow{
                border-bottom-color: rgba(0, 0, 0, 0.07);
                top: -9px;
                &:after{
                    margin-left: -9px
                }
            }
        }
        &-content{
            padding: 10px 15px 20px;
        }
    }
}





/* BADGES */
/*======================================*/
@if $badge == true {
    #container .badge{
        font-size: .9em;
        font-weight: $font-semibold;
        &:not(.badge-default){
            color: #fff;
        }
    }

    .badge:empty.badge-icon{
        display: inline-block;
        width: 0.7em;
        height: 0.7em;
        padding: 0;
        min-width: 5px;
        margin: .5em;
        border-radius: 50%;
    }
    .badge.badge-fw, .badge:empty.badge-fw{
        margin-right: 1em
    }
    .badge-stat{
        position: absolute;
        right: 0;
        top: 0;
        margin: 0px 7px 0 0 !important;
        box-shadow: 0 0 0 2px
    }

    .badge-default{
        background-color: $gray-bg;
        color: $gray-color
    }
    .badge-primary{
        background-color: $primary-bg
    }
    .badge-info{
        background-color: $info-bg
    }
    .badge-success{
        background-color: $success-bg
    }
    .badge-warning{
        background-color: $warning-bg
    }
    .badge-danger{
        background-color: $danger-bg
    }
    @if $enable-mint == true {
        .badge-mint{background-color: $mint-bg}
    }
    @if $enable-purple == true {
        .badge-purple{background-color: $purple-bg}
    }
    @if $enable-pink == true {
        .badge-pink{background-color: $pink-bg}
    }
    @if $enable-dark == true {
        .badge-dark{background-color: $dark-bg}
    }
}





/* DROPDOWNS */
/*======================================*/
@if $dropdown == true {
    .dropdown{
        &-header{
            font-weight: 700;
            font-size: .85em;
            color: $body-main-color;
            padding: 12px 15px 5px !important;
            text-transform: uppercase
        }
        &-toggle{
            > .dropdown-caret{
                display: inline-block;
                width: 0;
                height: 0;
                margin: 0 3px;
                border-style: solid;
                border-width: 6px 4px 0 4px;
                border-left-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                vertical-align: middle;
                &.caret-up{
                    border-width: 0 4px 6px 4px;
                    border-bottom-color: initial;
                    border-top-color: transparent
                }
            }
        }
    }



    .dropdown-menu{
        font-size: $font-size-base;
        border-radius: $border-radius-base;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        margin: 5px 0 0;
        padding: 0;
        border: 0;
        > .active > a{
            background-color: $state-active-bg !important;
            border-color: $state-active-bg !important;
            color: $state-active-color !important;
        }
        > li{
            padding: 2px 7px;
            > a{
                border-radius: $border-radius-base;
                color: $body-color
            }
            &:first-child{
                padding-top: 7px
            }
            &:last-child{
                padding-bottom: 7px
            }
            &.divider{
                padding: 0;
                margin: 5px 0
            }
        }
        &-right{
            left: auto;
            right: 0
        }
        &:not(.head-list) >li{
            >a{
                padding: 7px 10px;
                &:hover{
                    background-color: $state-active-bg;
                    color: $state-active-color
                }
            }
        }
        &.with-arrow{
            &:before, &:after{
                content: "";
                display: block;
                position: absolute;
                width: 0;
                height: 0;
                left: 0;
                top: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
            }
            &:before{
                border-bottom: 7px solid darken($border-color-base, 10%);
                margin: -7px 0 0 15px;
            }
            &:after{
                border-bottom: 7px solid #fff;
                margin: -6px 0 0 15px;
            }
        }
        &-right.dropdown-menu.with-arrow{
            &:before{
                left: auto;
                right: 0;
                margin: -7px 25px 0 0;
            }
            &:after{
                left: auto;
                right: 0;
                margin: -6px 25px 0 0;
            }
        }
    }
    .dropup .dropdown-menu{
        margin-bottom: 5px
    }


    // Sizes
    // ----------------------------------------------
    .dropdown-menu{
        &-sm{
            min-width: 220px;
        }
        &-md{
            min-width: 270px;
        }
        &-lg{
            min-width: 300px;
        }
    }

    .dropdown.open > .dropdown-toggle,
    .btn-group.open .dropdown-toggle{
        box-shadow: inset 0 3px 1px rgba(0, 0, 0, 0.3) !important;
    }

}






/* WELLS */
/*======================================*/
@if $well == true {
    .well, pre{
        background-color:  $gray-bg;
        border-color:  $border-color-base;
        border-radius: $border-radius-base;
        box-shadow: none;
        color: inherit
    }

    .well-xs{
        padding: 5px;
    }
}







/* PROGRESSBARS */
/*======================================*/

// Mixin
// ----------------------------------------------
@mixin progress-sizes($height, $margin-btm, $progress-font-size){
    height: $height;
    margin-bottom: $margin-btm;
    .progress-bar{
        font-size: $progress-font-size;
        line-height: $height;
    }
}

@if $progressbar == true {
    .progress{
        height: 12px;
        margin-bottom: 15px;
        border-radius: $border-radius-base;
        box-shadow: none;
        background-color: rgba(0,0,0,0.1);
        &-bar{
            font-size: 10px;
            line-height: 1.05em;
            background-color: $primary-bg;
            box-shadow: none
        }
        &-light-base{
            background-color: #fff;
        }
        &-dark-base{
            background-color: rgba(255,255,255,0.2)
        }

    }



    // Sizes
    // ----------------------------------------------
    .progress-xl{@include progress-sizes(30px, 20px, $font-size-base)}
    .progress-lg{@include progress-sizes(20px, 20px, $font-size-base)}
    .progress-md{@include progress-sizes(8px, 5px, 5px)}
    .progress-sm{@include progress-sizes(4px, 5px, 0)}
    .progress-xs{@include progress-sizes(2px, 10px, 0)}


    // Colors
    // ----------------------------------------------
    .progress-bar-light{background-color: #fff}
    .progress-bar-primary{background-color: $primary-bg}
    .progress-bar-info{background-color: $info-bg}
    .progress-bar-success{background-color: $success-bg}
    .progress-bar-warning{background-color: $warning-bg}
    .progress-bar-danger{background-color: $danger-bg}
    @if $enable-mint == true {
        .progress-bar-mint{background-color: $mint-bg}
    }
    @if $enable-purple == true {
        .progress-bar-purple{background-color: $purple-bg}
    }
    @if $enable-pink == true {
        .progress-bar-pink{background-color: $pink-bg}
    }
    @if $enable-dark == true {
        .progress-bar-dark{background-color: $dark-bg}
    }

}




/*======================================*/
/* PAGERS */
/*======================================*/
%bspagerdisabled{
    border-color: darken($border-color-base, 5%);
    box-shadow: none
}

%bspagerlinkactive{
    box-shadow: inset 0 3px 2px rgba(0, 0, 0, 0.3) !important;
    background-color: $state-active-bg !important;
    color: $state-active-color !important;
}

%bspagerlinkhover{
    background-color: $light-bg;
    border-color: $state-active-bg;
    color: $state-active-bg;
    box-shadow: inset 0 0 1px $state-active-bg,  0 3px 15px rgba(0,0,0,.25);
    z-index: 2;
    @include transition(all, .3s)
}


@if $pager == true {
    .pager{
        li{
            > a, > span{
                border-radius: $border-radius-base;
                border-color: darken($border-color-base, 2%);
                color: $body-color;
            }
            &:not(.disable) >a{
                @include transition(all .3s);
                &:active{
                    @extend %bspagerlinkactive;
                }
                &:hover,&:focus{
                    @extend %bspagerlinkhover;
                }
            }
        }
        &.pager-rounded{
            li{
                >a, > span{
                    border-radius: 15px;
                }
            }
        }
        .disabled{
            > a, > span{
                background-color: transparent !important;
                border-color: darken($border-color-base, 2%) !important;
                box-shadow: none !important;
                color: darken($body-color, 25%) !important;
            }
        }
    }
}




/*======================================*/
/* PAGINATION */
/*======================================*/
@if $pagination == true{
    .pagination{
        >li{
            color: $body-color;
            >a, >span{
                color: inherit;
                border-color: darken($border-color-base,5%);
                @include transition(border-color,.3s)
            }
            >span{
                cursor: default;
                &:hover{
                    background-color: $light-bg
                }
            }
            &:first-child{
                >a, span{
                    border-top-left-radius: $border-radius-base;
                    border-bottom-left-radius: $border-radius-base;
                }
            }
            &:last-child{
                >a, span{
                    border-top-right-radius: $border-radius-base;
                    border-bottom-right-radius: $border-radius-base;
                }
            }
            &:not(.disabled):not(.active) a {
                &:active{
                    @extend %bspagerlinkactive;
                }
                &:hover,&:focus{
                    @extend %bspagerlinkhover;
                }
            }


        }
        >.active{
            >a, >span, >a:hover, >span:hover, >a:focus, >span:focus{
                background-color: $state-active-bg;
                border-color: $state-active-bg;
            }
        }
        .disabled{
            > a, > span{
                box-shadow: none !important;
                color: lighten($body-color, 35%) !important;
                background-color: transparent !important
            }
        }
    }
}





/* CAROUSEL                             */
/*======================================*/
@if $carousel == true{
    .carousel{
        &-inner > .item{
            padding-top: 15px;
        }
        &-control{
            &.left, &.right{
                background-image: none;
                background-repeat: no-repeat;
                color: inherit
            }
            &,&:focus{
                font-size: 1em;
                text-shadow: none;
                width: auto;
                padding: 10px;
                top: 0;
                bottom: 0;
                opacity: .5;
                @include transition(opacity .5s)
            }
            &.auto-hide{
                opacity: 0
            }
            &:before{
                content: '';
                display: inline-block;
                height: 100%;
                width: 0;
                vertical-align: middle
            }
            i{
                position: relative;
                top: .25em
            }
        }
        &:hover{
            .carousel-control{
                opacity: 1;
                @include transition(opacity .5s);
            }
        }
        &-indicators{
            &.out{
                bottom: 0;
                + .carousel-inner{
                    padding-bottom: 30px;
                }
            }
            &.square{
                li{
                    border-radius: 1px;
                }
            }
            > .active{
                background-color: transparent;
                box-shadow:inset 0 0 0 50px;
                border-color: transparent
            }
            > li{
                border-color: inherit;
            }
        }
    }
}





/* BLOCKQUOTE */
/*======================================*/
blockquote{
    border-left: 3px solid rgba(0,0,0,.2);
    position: relative;
    text-indent: 3ex
}
.bq{
    &-sm{
        font-size: $font-size-base;
    }
    &-open:before, &-close:after{
        content: '\201C';
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 3.5em;
    }
    &-open:before{
        position: absolute;
        top: 0.07em;
        left: 20px;
        text-indent: 0;
        line-height: 1em
    }
    &-close:after{
        content: '\201D';
        display: inline-block;
        line-height: 0;
        vertical-align: text-bottom;
        @include transform(translate(-.5ex, .1ex));

    }
}



/* FORM */
/*======================================*/
.form-horizontal{
    .control-label{
        margin-bottom: 5px;
        &.text-left{
            text-align: left
        }
    }
}





/* ACCORDION */
/*======================================*/
@if $accordion == true {
    .panel{
        > .panel-heading + .panel-collapse > .panel-body{
            border-top: 0
        }
        &-group{
            margin-bottom: 35px;
            &.accordion{
                .panel{
                    margin-bottom: 0;
                    &-heading{
                        border-radius: 0;
                        height: auto;
                        &:after{
                            border: 0 !important
                        }
                    }
                    &-heading, &-title{
                        padding: 0;
                    }
                    &-title{
                        line-height: 40px;
                        a{
                            display: block;
                            color: inherit;
                            text-transform: none;
                            font-size: $font-size-base + 1;
                            padding: 0 15px;
                            &:hover,&:focus{
                                color: inherit
                            }
                            &.collapsed{
                                > i{
                                    transform: rotate(0);
                                    transition: all .3s
                                }
                            }
                            > i {
                                display: inline-block;
                                min-width: 2ex;
                                text-align: center;
                                transform-origin: center;
                                transform: rotate(90deg);
                                transition: all .3s;
                            }
                        }
                    }
                    &:first-child{
                        &, .panel-heading{
                            border-top-left-radius: $border-radius-base;
                            border-top-right-radius: $border-radius-base;
                        }
                    }
                    &:last-child{
                        border-bottom-left-radius: $border-radius-base;
                        border-bottom-right-radius: $border-radius-base;
                        overflow: hidden;
                    }
                }
            }
            &-trans{
                .panel{
                    background-color: transparent !important;
                    box-shadow: none
                }

            }
            &-condensed{
                .panel-title a{
                    line-height: 35px
                }
                .panel-body{
                    padding: 10px 20px 10px
                }
            }
        }
    }
    .panel-group-trans.panel-group-condensed{
        .panel-title a{
            padding: 0
        }
        .panel-body{
            padding: 10px 0 10px
        }
    }
}




/* TAB */
/*======================================*/
@if $tabs == true{
    .tab{
        &-base{
            margin-bottom: 15px;
            .tab-content{
                background-color: $light-bg;
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
                padding: 15px;
                .tab-footer{
                    background-color: lighten($body-bg,3%);
                    color: $body-color;
                    border-color: lighten($body-bg,1%);
                    position: relative;
                    margin: 0 -15px -15px -15px;
                    padding: 10px 15px
                }
            }
            .nav-tabs{
                border: 0;
                >li:not(.active){
                    >a{
                        background-color: darken($body-bg, 5%);
                        @include transition(background-color,.3s);
                        &:hover{
                            background-color: rgba(255, 255, 255, 0.55);
                            border-color: transparent;
                            @include transition(background-color,.3s);
                        }
                    }
                }
                > .active{
                    > a, a:hover, > a:focus{
                        border-color: transparent
                    }
                }
                &.tabs-right{
                    text-align: right;
                    > li {
                        float: none;
                        display: inline-block;
                        margin-right: -2px;
                    }
                }
            }
        }
        &-footer:after{
            content: '';
            display: table;
            clear: both
        }

    }

    .nav-tabs{
        li a{
            color: $body-color;
            border-radius: $border-radius-base $border-radius-base 0 0
        }
        > li.active{
            > a, > a:hover, > a:focus{
                color: $body-main-color;
                border-color: transparent;
                border-bottom-color: transparent
            }
        }
        &.tab-right{
            text-align: right;
            >li{
                display: inline-block;
                text-align: left;
                float: none;
                >a {
                    margin-right: 0
                }
            }
        }
        .label, .badge{
            margin-left: 4px
        }
    }

    .tab-stacked{
        &-left, &-right{
            display: table;
            height: 100%;
            width: 100%;
            & .nav-tabs{
                >li{
                    float: none;
                    margin: 0;
                    >a{
                        margin: 0 0 2px;
                    }
                    &:last-child{
                        > a{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            & .nav-tabs, & .tab-content{
                display: table-cell;
                vertical-align: top
            }
            .tab-content{
                overflow: hidden;
            }
        }
        &-left{
            .nav-tabs{
                width: 1%;
                border: 0;
                >li{
                    a{
                        border-right-color: transparent;
                        border-radius: $border-radius-base 0 0 $border-radius-base
                    }
                }
                >.active{
                    >a{
                        &:hover, &:focus{
                            border-right-color: transparent;
                        }
                    }
                }

            }
            &.tab-base{
                .nav-tabs{
                    >li:not(.active){
                        a{
                            &:hover{
                                border-right-color: transparent;
                            }
                        }
                    }
                }
            }
            .tab-content{
                border-left-color: transparent;
                border-radius: 0 $border-radius-base $border-radius-base 0;
            }
        }
        &-right{
            .nav-tabs{
                width: 1%;
                border: 0;
                >li{
                    a{
                        border-left-color: transparent;
                        border-radius: 0 $border-radius-base $border-radius-base 0
                    }
                }
                >.active{
                    >a{
                        &:hover, &:focus{
                            border-left-color: transparent;
                        }
                    }
                }
            }
            &.tab-base{
                .nav-tabs{
                    >li:not(.active){
                        a{
                            &:hover{
                                border-left-color: transparent;
                            }
                        }
                    }
                }
            }
            .tab-content{
                border-right-color: transparent;
                border-radius: $border-radius-base 0 0 $border-radius-base
            }
        }
    }
}




/* BUTTONS */
/*======================================*/

// Mixin
// ------------------------------------
@mixin btn-border($bg){
    @if lightness($bg) >= 70%{
        border-color: $border-color-base !important;
    }@else{
        border-color: $bg !important;
    }
}

@mixin btn-variant($color, $background){
    &, &:focus{
        background-color: lighten($background, 3%);
        @include btn-border($background);
        color: $color;
    }

    @at-root{
        &:hover, &:active, &.active, &:disabled, &.disabled,
        &:not([class*="btn-active-"]) + .dropdown-menu > li >a:hover,
        &:not([class*="btn-active-"]) + .dropdown-menu > li >a:focus,
        &:not([class*="btn-active-"]) + .dropdown-menu > .active > a,
        & + .dropdown-menu:not(.head-list) > li > a.active,
        .open > .dropdown-toggle#{&}{
            background-color: darken($background, 7%) !important;
            @include btn-border(darken($background,7%));
            color: $color !important;
        }
    }
}


@if $buttons == true {
    .btn {
        cursor: pointer;
        background-color: transparent;
        color: inherit;
        padding: 6px 12px;
        border-radius: $border-radius-base;
        border: 1px solid transparent;
        font-size: $font-size-base;
        line-height: 1.475;
        vertical-align: middle;
        @include transition(all .25s);
        &:not(.disabled):not(:disabled):hover{
            box-shadow: 0 3px 15px rgba(0,0,0,.25);
        }
        &.focus, &:focus, &:hover{
            color: inherit
        }
    }

    .btn:not(.disabled):not(:disabled):active,
    .btn:not(.disabled):not(:disabled).active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
    }


    /* Button Sizes */
    /*---------------------------------*/
    .btn-lg{
        font-size: $font-size-base + 4;
        line-height: 1.44
    }
    .btn-sm{
        font-size: $font-size-base - 2;
        line-height: 1.5
    }
    .btn-xs{
        font-size: $font-size-base - 2;
        line-height: 1.5
    }






    /* Button Icon */
    /*---------------------------------*/
    @if $include-btn-icon == true {
        .btn-icon{
            padding-left: 9px;
            padding-right: 9px;
            box-shadow: none !important;
            > i, &:before{
                display: inline-block;
                min-width: 1.05em
            }
            > i:before{
                line-height: inherit
            }
        }
        .btn-icon{
            &.btn-xs{
                @extend .btn-xs;
            }
            &.btn-sm{
                @extend .btn-sm;
            }
            &.btn-lg{
                @extend .btn-lg;
            }
            &:hover{
                transform: scale(1.2);
            }
        }
    }





    /* Button File */
    /*---------------------------------*/
    .btn-file {
        position: relative;
        overflow: hidden;
        input[type=file] {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 100%;
            min-height: 100%;
            font-size: 100px;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            background: white;
            cursor: inherit;
            display: block;
        }
    }


    /* Button Link */
    /* --------------------------------- */
    .btn-link{
        color: darken($info-bg, 7%);
        border-color: transparent;
        &:hover, &:focus, &:active{
            color: darken($info-bg, 15%);
        }
        @at-root .btn#{&}{
            &:focus, &:active{
                box-shadow: none
            }
        }
        &.disabled{
            &:hover, &:focus{
                text-decoration: none
            }
        }
    }




    /* Button Transparent */
    /*---------------------------------*/
    .btn-trans{
        padding-left: 5px;
        padding-right: 5px;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none !important;
    }




    // Buttons Variant
    // ------------------------------------
    .btn-default{
        @include btn-variant($light-color, $light-bg);
        i {
            color: inherit
        }
    }
    .btn-primary{@include btn-variant($primary-color, $primary-bg)}
    .btn-info{@include btn-variant($info-color, $info-bg)}
    .btn-success{@include btn-variant($success-color, $success-bg)}
    .btn-warning{@include btn-variant($warning-color, $warning-bg)}
    .btn-danger{@include btn-variant($danger-color, $danger-bg)}
    @if $enable-mint == true {
        .btn-mint{
            @include btn-variant($mint-color, $mint-bg);
        }
    }
    @if $enable-purple == true {
        .btn-purple{
            @include btn-variant($purple-color, $purple-bg);
        }
    }
    @if $enable-pink == true {
        .btn-pink{
            @include btn-variant($pink-color, $pink-bg);
        }
    }
    @if $enable-dark == true {
        .btn-dark{
            @include btn-variant($dark-color, $dark-bg);
        }
    }




    // Buttons Hover
    // ------------------------------------
    @if $include-btn-hover == true {
        .btn-hover-default{
            &:hover, &:active, &.active{ @extend .btn-default:hover; }
        }
        .btn-hover-primary{
            &:hover, &:active, &.active{ @extend .btn-primary:hover; }
        }
        .btn-hover-info{
            &:hover, &:active, &.active{ @extend .btn-info:hover; }
        }
        .btn-hover-success{
            &:hover, &:active, &.active{ @extend .btn-success:hover; }
        }
        .btn-hover-warning{
            &:hover, &:active, &.active{ @extend .btn-warning:hover; }
        }
        .btn-hover-danger{
            &:hover, &:active, &.active{ @extend .btn-danger:hover; }
        }
        @if $enable-mint == true {
            .btn-hover-mint{
                &:hover, &:active, &.active{ @extend .btn-mint:hover; }
            }
        }
        @if $enable-purple == true {
            .btn-hover-purple{
                &:hover, &:active, &.active{ @extend .btn-purple:hover; }
            }
        }
        @if $enable-pink == true {
            .btn-hover-pink{
                &:hover, &:active, &.active{ @extend .btn-pink:hover; }
            }
        }
        @if $enable-dark == true {
            .btn-hover-dark{
                &:hover, &:active, &.active{ @extend .btn-dark:hover; }
            }
        }
    }




    // Pressed Buttons / Active
    // ------------------------------------
    @if $include-btn-active == true {
        .btn-active-default{
            &.btn{
                &:active, &.active{
                    @extend .btn-default:active;
                }
            }
            @at-root{
                & + .dropdown-menu > li > a:hover,
                & + .dropdown-menu > li > a:focus,
                & + .dropdown-menu > .active > a,
                .open .dropdown-toggle.btn#{&},
                .btn-group.open .dropdown-toggle.btn#{&}{
                    @extend .btn-default:active;
                }
            }
        }


        .btn-active-primary{
            &.btn{
                &:active, &.active{
                    @extend .btn-primary:active;
                }
            }
            @at-root{
                & + .dropdown-menu > li > a:hover,
                & + .dropdown-menu > li > a:focus,
                & + .dropdown-menu > .active > a,
                .open .dropdown-toggle.btn#{&},
                .btn-group.open .dropdown-toggle.btn#{&}{
                    @extend .btn-primary:active;
                }
            }
        }

        .btn-active-info{
            &.btn{
                &:active, &.active{
                    @extend .btn-info:active;
                }
            }
            @at-root{
                & + .dropdown-menu > li > a:hover,
                & + .dropdown-menu > li > a:focus,
                & + .dropdown-menu > .active > a,
                .open .dropdown-toggle.btn#{&},
                .btn-group.open .dropdown-toggle.btn#{&}{
                    @extend .btn-info:active;
                }
            }
        }

        .btn-active-success{
            &.btn{
                &:active, &.active{
                    @extend .btn-success:active;
                }
            }
            @at-root{
                & + .dropdown-menu > li > a:hover,
                & + .dropdown-menu > li > a:focus,
                & + .dropdown-menu > .active > a,
                .open .dropdown-toggle.btn#{&},
                .btn-group.open .dropdown-toggle.btn#{&}{
                    @extend .btn-success:active;
                }
            }
        }

        .btn-active-warning{
            &.btn{
                &:active, &.active{
                    @extend .btn-warning:active;
                }
            }
            @at-root{
                & + .dropdown-menu > li > a:hover,
                & + .dropdown-menu > li > a:focus,
                & + .dropdown-menu > .active > a,
                .open .dropdown-toggle.btn#{&},
                .btn-group.open .dropdown-toggle.btn#{&}{
                    @extend .btn-warning:active;
                }
            }
        }


        .btn-active-danger{
            &.btn{
                &:active, &.active{
                    @extend .btn-danger:active;
                }
            }
            @at-root{
                & + .dropdown-menu > li > a:hover,
                & + .dropdown-menu > li > a:focus,
                & + .dropdown-menu > .active > a,
                .open .dropdown-toggle.btn#{&},
                .btn-group.open .dropdown-toggle.btn#{&}{
                    @extend .btn-danger:active;
                }
            }
        }


        @if $enable-mint == true {
            .btn-active-mint{
                &.btn{
                    &:active, &.active{
                        @extend .btn-mint:active;
                    }
                }
                @at-root{
                    & + .dropdown-menu > li > a:hover,
                    & + .dropdown-menu > li > a:focus,
                    & + .dropdown-menu > .active > a,
                    .open .dropdown-toggle.btn#{&},
                    .btn-group.open .dropdown-toggle.btn#{&}{
                        @extend .btn-mint:active;
                    }
                }
            }
        }
        @if $enable-purple == true {
            .btn-active-purple{
                &.btn{
                    &:active, &.active{
                        @extend .btn-purple:active;
                    }
                }
                @at-root{
                    & + .dropdown-menu > li > a:hover,
                    & + .dropdown-menu > li > a:focus,
                    & + .dropdown-menu > .active > a,
                    .open .dropdown-toggle.btn#{&},
                    .btn-group.open .dropdown-toggle.btn#{&}{
                        @extend .btn-purple:active;
                    }
                }
            }
        }
        @if $enable-pink == true {
            .btn-active-pink{
                &.btn{
                    &:active, &.active{
                        @extend .btn-pink:active;
                    }
                }
                @at-root{
                    & + .dropdown-menu > li > a:hover,
                    & + .dropdown-menu > li > a:focus,
                    & + .dropdown-menu > .active > a,
                    .open .dropdown-toggle.btn#{&},
                    .btn-group.open .dropdown-toggle.btn#{&}{
                        @extend .btn-pink:active;
                    }
                }
            }
        }
        @if $enable-dark == true {
            .btn-active-dark{
                &.btn{
                    &:active, &.dark{
                        @extend .btn-purple:active;
                    }
                }
                @at-root{
                    & + .dropdown-menu > li > a:hover,
                    & + .dropdown-menu > li > a:focus,
                    & + .dropdown-menu > .active > a,
                    .open .dropdown-toggle.btn#{&},
                    .btn-group.open .dropdown-toggle.btn#{&}{
                        @extend .btn-dark:active;
                    }
                }
            }
        }
    }




    .btn-group-vertical .btn:not(.btn-default):not(.btn-trans),
    .btn-group .btn:not(.btn-default):not(.btn-trans){
        border-color: rgba(0,0,0,0.09)
    }


    .btn-lg, .btn-group-lg > .btn {
        padding: 10px 16px;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 5px 10px;
    }

    .btn-xs, .btn-group-xs > .btn {
        padding: 1px 5px;
    }

    .btn-lg, .btn-group-lg > .btn{
        border-radius: $border-radius-base
    }

    .btn-lg, .btn-group-sm > .btn,
    .btn-lg, .btn-group-xs > .btn{
        border-radius: $border-radius-base
    }

    .btn-group-vertical {
        > .btn:first-child:not(:last-child){
            border-top-left-radius: $border-radius-base;
            border-top-right-radius: $border-radius-base
        }
        > .btn:last-child:not(:first-child){
            border-bottom-left-radius: $border-radius-base;
            border-bottom-right-radius: $border-radius-base
        }
    }





    /* Button Circle */
    /*---------------------------------*/
    @if $include-btn-circle {
        .btn-circle{
            padding: 7px;
            border-radius: 50%
        }
        .btn-lg.btn-circle {
            padding: 17px;
        }
        .btn-sm.btn-circle {
            padding: 7px;
        }
        .btn-xs.btn-circle {
            padding: 4px;
        }
        .btn-circle > i,
        .btn-circle:before{
            display: block;
            width: 1.4em;
            height: 1.4em;
            line-height: 1.4
        }
    }



    /* Button Rounded */
    /*---------------------------------*/
    @if $include-btn-rounded == true {
        .btn-rounded{
            border-radius: 17px;
            overflow: hidden
        }
        .btn-lg.btn-rounded{
            border-radius: 30px
        }
        .btn-sm.btn-rounded{
            border-radius: 15px
        }
        .btn-xs.btn-rounded{
            border-radius: 10px
        }
        .btn-group.btn-rounded{
            overflow: hidden
        }
    }


    /* Button Labeled */
    /*---------------------------------*/
    @if $include-btn-labeled == true {
        .btn-labeled,
        .btn-labeled.fa{
            text-align: left;
        }
        .btn-labeled:not(.btn-block):not(.form-icon) {
            font-family: inherit;
        }
        .btn-block.btn-labeled:not(.form-icon) {
            font-family: inherit;
        }
        .btn-labeled .btn-label,
        .btn-labeled:before{
            border-right: 1px solid rgba(0,0,0, .1);
            display: inline-block;
            font-size: 16px;
            vertical-align: middle !important;
            text-align: center;
            width: 2em;
            line-height: 1.42857 !important;
            margin: -.25em 5px -.23em -12px
        }
        .btn-labeled.fa{
            line-height: 1.42857
        }
        .btn-labeled.fa:before,
        .btn-labeled .fa:before{
            font-family: FontAwesome;
        }
        .btn-lg{
            &.btn-labeled{
                font-size: 17px;
                &.fa{
                    line-height: 1.33
                }
            }
            &.btn-labeled:before, .btn-label{
                font-size: 20px;
                margin-left: -16px;
            }
        }

        .btn-sm{
            &.btn-labeled{
                font-size: 11px;
                &.fa{
                    line-height: 1.42857
                }
            }
            &.btn-labeled:before, .btn-label{
                font-size: 14px;
                margin-left: -10px;
            }
        }

        .btn-xs{
            &.btn-labeled{
                font-size: 11px;
                &.fa{
                    line-height: 1.5
                }
            }
            &.btn-labeled:before, .btn-label{
                font-size: 12px;
                margin-left: -5px;
            }
        }
    }
}




/* PANELS */
/*======================================*/

// Mixin
//---------------------------------
@mixin panel-variant($color, $background){
    & .panel-heading,
    & .panel-footer,
    &.panel-colorful{
        background-color: $background;
        border-color: $background;
        color: $color;
    }
    &.panel-colorful{
        box-shadow:  0 1px 1px darken($background, 20%)
    }
}

@if $panels == true {
    .panel{
        border: 0;
        border-radius: $border-radius-base;
        box-shadow: none !important;
        margin-bottom: $grid-gutter-width;

        .panel-bg-cover{
            max-height: 180px;
            overflow: hidden;
            img{
                min-width: 100%;
                min-height: 100%;
                background-size: cover
            }
        }
        &.remove{
            opacity: 0;
            @include transition(opacity,.5s);
        }
        .alert{
            border-radius: 0
        }
    }


    .panel {
        &.panel-bg-img{
            position: relative;
        }
        .panel-bg-wrap {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            > img{
                position: absolute;
                top: 0;
                left: 0;
            }
            + .panel-body{
                position: relative
            }
        }
    }



    .panel-media{
        box-shadow: 0 -50px 20px -10px rgba(0, 0, 0, 0.2);
        padding: 10px 15px 15px 140px;
        position: relative;
        &-img{
            position: absolute;
            width: 96px;
            height: 96px;
            left: 20px;
            top: -48px;
        }
        &-heading{
            color: #fff;
            position: absolute;
            top: -2.7em
        }
    }




    .panel .panel-heading,
    .panel > :first-child{
        border-top-left-radius: max(0px, $border-radius-base - 1px);
        border-top-right-radius: max(0px, $border-radius-base - 1px);
    }
    .panel .panel-footer,
    .panel > :last-child{
        border-bottom-left-radius: max(0px, $border-radius-base - 1px);
        border-bottom-right-radius: max(0px, $border-radius-base - 1px);
    }
    .panel-body-full{
        margin-left: -20px;
        margin-right: -20px;
    }
    .panel-body{
        padding: 15px 20px 25px;
    }
    .panel-trans{
        border-color: transparent;
        box-shadow: none;
        background-color: transparent
    }
    .panel-heading{
        position: relative;
        height: $panel-heading-height;
        padding: 0;
        color: $panel-default-head-color;
        .btn{
            box-shadow: none !important;
        }
    }
    .panel-title{
        font-weight: normal;
        padding: 0 20px 0 20px;
        font-size: 1.15em;
        line-height: $panel-heading-height;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }




    .panel-default.panel-colorful{
        background-color: darken($gray-bg,1%);
        color: $gray-color;
    }

    .panel-default .panel-heading{
        background-color: darken($gray-bg,1%);
        border-color: lighten($gray-bg,4%);
    }

    .panel-footer{
        background-color: lighten($body-bg, 5%);
        color: $body-color;
        border-color: rgba(0, 0, 0, 0.02);
        position: relative
    }




    /* Colors */
    /*---------------------------------*/
    .panel-primary{@include panel-variant($primary-color, $primary-bg)}
    .panel-info{@include panel-variant($info-color, $info-bg)}
    .panel-success{@include panel-variant($success-color, $success-bg)}
    .panel-warning{@include panel-variant($warning-color, $warning-bg)}
    .panel-danger{@include panel-variant($danger-color, $danger-bg)}
    @if $enable-mint == true {
        .panel-mint{@include panel-variant($mint-color, $mint-bg)}
    }
    @if $enable-purple == true {
        .panel-purple{@include panel-variant($purple-color, $purple-bg)}
    }
    @if $enable-pink == true {
        .panel-pink{@include panel-variant($pink-color, $pink-bg)}
    }
    @if $enable-dark == true {
        .panel-dark{@include panel-variant($dark-color, $dark-bg)}
    }





    .panel-colorful > .panel-heading{
        border: 0
    }
    .panel-default > .panel-heading:after, .panel-primary > .panel-heading:after, .panel-info > .panel-heading:after,
    .panel-success > .panel-heading:after, .panel-warning > .panel-heading:after, .panel-danger > .panel-heading:after,
    .panel-purple > .panel-heading:after, .panel-pink > .panel-heading:after, .panel-dark > .panel-heading:after{
        display: none
    }



    /* Bordered */
    /*---------------------------------*/
    .panel-bordered-default, .panel-default.panel-bordered{
        border: 1px solid darken($gray-bg, 15%)
    }
    .panel-bordered-primary, .panel-primary.panel-bordered{
        border: 1px solid $primary-bg
    }
    .panel-bordered-info, .panel-info.panel-bordered{
        border: 1px solid $info-bg
    }
    .panel-bordered-success, .panel-success.panel-bordered{
        border: 1px solid $success-bg
    }
    .panel-bordered-warning, .panel-warning.panel-bordered{
        border: 1px solid $warning-bg
    }
    .panel-bordered-danger,	.panel-danger.panel-bordered{
        border: 1px solid $danger-bg
    }
    @if $enable-mint == true {
        .panel-bordered-mint, .panel-mint.panel-bordered{
            border: 1px solid $mint-bg
        }
    }
    @if $enable-purple == true {
        .panel-bordered-purple, .panel-purple.panel-bordered{
            border: 1px solid $purple-bg
        }
    }
    @if $enable-pink == true {
        .panel-bordered-pink, .panel-pink.panel-bordered{
            border: 1px solid $pink-bg
        }
    }
    @if $enable-dark == true {
        .panel-bordered-dark, .panel-dark.panel-bordered{
            border: 1px solid $dark-bg
        }
    }






    /* Panel Group */
    /*---------------------------------*/
    .panel-group{
        .panel{
            border-radius: 0;
            margin-bottom: 20px
        }
        > div{
            padding-left: 0;
            padding-right: 0;
            &:first-child > .panel{
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
            }
            &:last-child > .panel{
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
            + div > .panel{
                margin-left: -1px
            }
        }
    }





    /* Panel Control */
    /*---------------------------------*/
    .panel-control{
        height: 100%;
        position: relative;
        float: right;
        padding: 0 15px 0 5px;
        &:before{
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            left: -1em;
            position: relative;
        }
        .btn{
            padding-left: 7px;
            padding-right: 7px;
        }
        .dropdown{
            display: inline-block
        }
        > i,
        > .badge ,
        > label {
            vertical-align: middle
        }
        > .toggle-switch + label{
            vertical-align: baseline
        }
        > .form-control{
            display: inline-block;
            max-width: 98%;
            height: 32px;
            vertical-align: middle
        }
        > .input-group-wrap {
            display: inline-block;
            max-width: 200px;
            vertical-align: middle;
            > .input-group{
                .input-group-addon > *, .input-group-btn > *, .form-control{
                    height: 32px
                }
                .btn{
                    padding: 6px 12px;
                }
            }
        }
    }
    @media (min-width: 640px){
        .panel-control > .input-group-wrap {
            max-width: 300px;
        }
    }



    /* Panel minimize maximize button */
    /*---------------------------------*/
    [data-panel="minmax"]{
        &[aria-expanded="true"] i{
            @include transition(transform, .1s);
        }
        &[aria-expanded="false"] i{
            @include transition(transform, .1s);
            @include transform(rotate(180deg))
        }
    }



    /* Panel minimize maximize button */
    /*---------------------------------*/
    [data-panel="fullscreen"] > .icon-min{
        display: none;
    }
    .panel-fullscreen{
        overflow-y: hidden;
        .icon-min{
            display: inline-block
        }
        .icon-max{
            display: none;
        }
    }
    .panel.fullscreen{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
    }



    /* Panel with tabs */
    /*---------------------------------*/
    @if $panel-w-tabs == true {
        .panel{
            .panel-control .nav-tabs{
                > .active > a{
                    background-color: transparent;
                    box-shadow: inset 0 -2px 0 0 darken($state-active-bg, 5%) !important;
                    color: inherit
                }
            }
            &[class*="panel-"] .panel-control .nav-tabs{
                > .active > a{
                    box-shadow: inset 0 -2px 0 0 rgba(255,255,255,0.7) !important;
                }
            }
        }
        $max-tabs-height : 40px;
        .panel-control{
            .nav-tabs{
                @if $panel-heading-height > $max-tabs-height{
                    display: inline-block;
                    height: $max-tabs-height;
                    margin-top: $panel-heading-height - $max-tabs-height;
                    vertical-align: bottom;
                }
                @if $panel-heading-height <= $max-tabs-height{
                    height: 100%;
                    margin-top: -1px;
                }
                border: 0;
                > li{
                    margin-top: 1px;
                    margin-right: 5px;
                    height: 100%;
                    > a{
                        border-radius: 0;
                        margin-right: 0;
                        height: 100%;
                        @if $panel-heading-height > $max-tabs-height{
                            line-height: $max-tabs-height;
                            border-top-left-radius: $border-radius-base;
                            border-top-right-radius: $border-radius-base;
                        }
                        @if $panel-heading-height <= $max-tabs-height{
                            line-height: $panel-heading-height;
                        }
                        padding: 0 15px
                    }
                    &:not(.active){
                        background-color: transparent;
                        opacity: .6;
                        a{
                            border-bottom: 0 !important;
                            color: inherit;
                            &:hover{
                                background-color: rgba(0,0,0,.15);
                                border-color: transparent
                            }
                        }
                    }
                }
                > .active > a{
                    z-index: 1;
                }
            }
        }


        @if $panel-heading-height <= $max-tabs-height{
            .panel-control .nav-tabs > li:last-child.active > a{
                &, &:hover, &:focus{
                    border-right-color: transparent;
                    border-top-right-radius: $border-radius-base
                }
            }
        }

    }





    /* Panel with progress bar */
    /*---------------------------------*/
    @if $panel-w-progressbar == true {
        //With Progress Bar
        .panel-control .progress{
            min-width: 150px;
            margin: 0;
            display: inline-block;
            vertical-align: middle;
        }
    }



    /* Panel with switch */
    /*---------------------------------*/
    .panel-control .switchery{
        margin-left: 15px;
        vertical-align: middle
    }



    /* Panel with pager */
    /*---------------------------------*/
    @if $panel-w-pager == true {
        .panel-control .pager{
            margin: 0;
            display: inline-block;
            vertical-align: middle;
            border-radius: 0;
        }
    }


    /* Panel with pagination */
    /*---------------------------------*/
    @if $panel-w-pagination == true {
        .panel-control .pagination{
            margin: 0;
            border-radius: 0;
            vertical-align: middle;
            > li{
                > a, > span{
                    padding: 0 10px;
                    border: $border-radius-base $border-radius-base 0 0;
                    border-color: rgba(0,0,0,.09);
                    box-shadow: none;
                    height: 100%;
                    line-height: 30px;
                }
                &:not(.active):not(.disabled) > a:hover{
                    background-color: rgba(0,0,0,0.05);
                    border-color: rgba(0,0,0,.09);
                }
            }
            > .disabled > a{
                &, &:hover, &:active{
                    border-color: rgba(0,0,0,.09);
                }
            }
        }
        .panel-control .pagination > li:not(.active) > a, .pagination > li > a{
            background-color: transparent;
            color: inherit;
        }
        .panel-control .pagination > li > a:hover, .pagination > li > a:focus{
            box-shadow: none;
        }
    }



    /* Panel with table */
    /*---------------------------------*/
    .panel{
        > .panel-body + .table,
        > .panel-body + .table-responsive,
        > .table + .panel-body,
        > .table-responsive + .panel-body{
            border: 0
        }
    }



    /* Panel with buttons */
    /*---------------------------------*/
    @if $panel-w-btn == true {
        .panel-control .btn,
        .panel-control .dropdown-toggle.btn{
            border: 0;
        }
        .panel-control .open > .btn,
        .panel-control .btn.active,
        .panel-control .btn:active{
            box-shadow: none !important
        }
        .panel-control .btn-default{
            background-color: transparent;
            color: inherit
        }
    }
}









/* BACKGROUNDS */
/*======================================*/

@mixin bg-variant($color, $background){
    background-color: $background;
    &, & a{
        color: $color;
    }
    & a:hover, & a:focus{
        color: $color
    }
}

@if $bg == true {
    .bg-trans{
        background-color: transparent
    }
    .bg-light{@include bg-variant($light-color, $light-bg)}
    .bg-gray-light{@include bg-variant($gray-color, lighten($gray-bg,5%))}
    .bg-gray{@include bg-variant($gray-color, $gray-bg)}
    .bg-gray-dark{@include bg-variant($gray-color, darken($gray-bg,10%))}
    .bg-trans-light{@include bg-variant(inherit, rgba(255,255,255,.1))}
    .bg-trans-dark{@include bg-variant(inherit, rgba(0,0,0,.1))}

    .bg-primary{@include bg-variant($primary-color, $primary-bg)}
    .bg-info{@include bg-variant($info-color, $info-bg)}
    .bg-success{@include bg-variant($success-color, $success-bg)}
    .bg-warning{@include bg-variant($warning-color, $warning-bg)}
    .bg-danger{@include bg-variant($danger-color, $danger-bg)}

    @if $enable-mint == true {
        .bg-mint{@include bg-variant($mint-color, $mint-bg)}
    }
    @if $enable-purple == true {
        .bg-purple{@include bg-variant($purple-color, $purple-bg)}
    }
    @if $enable-pink == true {
        .bg-pink{@include bg-variant($pink-color, $pink-bg)}
    }
    @if $enable-dark == true {
        .bg-dark{@include bg-variant($dark-color, $dark-bg)}
    }

}








/* TEXTS */
/*======================================*/

@mixin text-color-variant($color){
    @at-root {
        &, a#{&}:hover, a#{&}:focus{
            color : darken($color, 7%)
        }
    }
}

@if $txt == true {
    .text-light, a.text-light:hover, a.text-light:focus{
        color : $light-bg
    }
    .text-muted{@include text-color-variant(lighten($text-muted, 5%))}
    .text-primary{@include text-color-variant($primary-bg)}
    .text-info{@include text-color-variant($info-bg)}
    .text-success{@include text-color-variant($success-bg)}
    .text-warning{@include text-color-variant($warning-bg)}
    .text-danger{@include text-color-variant($danger-bg)}
    .text-main{
         @at-root {
            &, a#{&}:hover, a#{&}:focus{
                color : $body-main-color
            }
        }
    }

    @if $enable-mint == true {
        .text-mint{@include text-color-variant($mint-bg)}
    }
    @if $enable-purple == true {
        .text-purple{@include text-color-variant($purple-bg)}
    }
    @if $enable-pink == true {
        .text-pink{@include text-color-variant($pink-bg)}
    }
    @if $enable-dark == true {
        .text-dark{@include text-color-variant($dark-bg)}
    }

}

.modal-notification-icon {
  font-size: 20px;
  margin: 5px 10px 5px 0;
}
.modal-notification-text {
  vertical-align: middle;
}
