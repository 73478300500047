@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(../../assets/fonts/mem5YaGs126MiZpBA-UN_r8OUuhv.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(../../assets/fonts/mem8YaGs126MiZpBA-UFVZ0d.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(../../assets/fonts/mem5YaGs126MiZpBA-UNirkOUuhv.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../../assets/fonts/mem5YaGs126MiZpBA-UN7rgOUuhv.woff) format('woff');
}



#container.cls-container.login-container{
  position: absolute;
}



.btn-link{
  cursor: pointer;
}
.btn-a{
  cursor: pointer;
}
.text-center{
  text-align:center;
}

.display-none{
  display: none !important;
}

.snackbar-error {
  background-color: #DB2424 !important;
  color: white !important;
}
.cls-content .fa-spinner{
  display: inline-block !important;
  margin-left: 5px;
}

.snackbar-success .mat-simple-snackbar-action,
.snackbar-success .mat-simple-snackbar,
.snackbar-error .mat-simple-snackbar,
.snackbar-error .mat-simple-snackbar-action{
  color: white !important;
}


.login-container{
  .login-logo-container{
    display: block;

    .login-logo{
      margin: 5px auto;
      width: 90%;
      height: auto;
      float: none;
      display: block;
    }
  }
  .form-group {
    min-height: 50px;
  }
  form.compact {
    .form-group {
      min-height: 30px;
    }
  }
  .options-login-container{
    margin-top: 0;
    padding-top: 0;
    div{
      margin-bottom: 6px;
      a{
        font-weight: 600;
      }
    }
  }
  .bg-img-login{
    display:none;
  }
  .bg-img ~ .cls-content .panel {
    background-color: transparent;
  }
}




@media (min-width: 768px) {
  #container.cls-container.login-container{
    position: relative;
  }
  .login-container{
    .bg-img-login{
      display:inherit;
      background-image: url('../../assets/images/bg-img/swisseldex_bg_bilder_04.jpg')
    }

    .panel{
      border-radius: 7px;
    }
    .bg-img ~ .cls-content .panel {
      background-color: #fff;
    }
    .alert-login-action{
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}
.alert.alert-loading {
  background-color: #55b1da;
  color: white;
}
