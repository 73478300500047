/* You can add global styles to this file, and also import other style files */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import '@angular/material/prebuilt-themes/indigo-pink.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "palette";
@import "breakpoints";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$swisseldex-datahub-pwa-primary: mat.m2-define-palette($swisseldex-primary-palette, 400);
$swisseldex-datahub-pwa-accent: mat.m2-define-palette($swisseldex-secondary-palette, 700);

// The warn palette is optional (defaults to red).
$swisseldex-datahub-pwa-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$swisseldex-datahub-pwa-theme: mat.m2-define-light-theme((
  color: (
    primary: $swisseldex-datahub-pwa-primary,
    accent: $swisseldex-datahub-pwa-accent,
    warn: $swisseldex-datahub-pwa-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($swisseldex-datahub-pwa-theme);

.details-list {
  display: inline-block;
  width: 100%;
  .details-item {
    padding-left: 0;
    padding-right: 0;
    min-height: 1px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100%;
    .details-label {
      display: inline-block;
      width: 100%;
      margin: 0;
    }
    .details-value {
      color: #3c4045;
      width: 100%;
      padding: 10px;
      margin: 0;
    }
    .details-value + .details-value {
      padding-top: 0;
      margin-top: -10px;
    }
  }
}

@mixin muted {
  color: $muted-text;
}

@mixin flex-spaced {
  display: flex;
  justify-content: space-between;
}

.flex-spaced {
  @include flex-spaced;
}

.button-stepper {
  display: flex;
  height: 48px;
  gap: 8px;
  align-items: center;
  font-weight: bold;
  background: $table-odd;
  border-radius: 24px;
  margin-right: 8px;
}

.button-align {
  display: inline-block;
  padding: 12px 0;
  height: 48px;
  margin-right: 12px;
  vertical-align: bottom;
}
.mat-tree {
  background: transparent;
}

.space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

mat-nav-list {
  height: 100%;
}

#container .mat-mdc-cell {
  font-family: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif", sans-serif;
}

.headline-space-button {
  @include flex-spaced;
  margin-bottom: 12px;
  .mdc-button + .mdc-button, .mdc-button + submit-button, submit-button + .mdc-button {
    margin-left: 8px;
  }
  h2 {
    margin: 0;
    height: 48px;
    padding: 12px;
  }
}

body .companypartners-container,
.cdk-overlay-pane,
.companypartners-container .mat-drawer-container,
.companypartners-container .mat-sidenav-container {
  color: $dark-primary-text;
  background: $background-light;
  font-size: 13px;
}
.companypartners-container {
  min-height: 100vh;
}
.companypartners-container, .cdk-overlay-container {
  * {
    box-sizing: border-box;
    font-family: $font-family;
  }

  .mat-mdc-icon-button svg {
    vertical-align: top;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $dark-primary-text;
  }

  h1, .h1 {
    font-size: 24px;
    margin-top: 6px;
  }

  h2, .h2 {
    font-size: 18px;
  }

  h3, .h3 {
    font-size: 16px;
  }

  h4, h5, h6, .h4, .h5, .h6 {
    font-size: 14px;
  }

  .align-end {
    text-align: end;
  }

  .large-icon {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    margin-right: 12px;
  }

  .button-fit {
    width: 48px;
    height: 48px;
    padding: 12px;
  }

  a.muted {
    @include muted;
    text-decoration: underline;
  }

  .muted {
    @include muted;
  }

  .shaded-content {
    border: 1px solid $border-shaded;
    background: $background-shaded;
    padding: 24px;
  }

  .h1-back-button {
    margin-top: -11px;
    margin-bottom: -11px;
    vertical-align: top;
  }

  button {
    .icon-in-text-button {
      width: 24px;
      height: 24px;
    }
  }

  .text-icon-prefix {
    display: flex;
    flex-direction: row;
    align-items: center;
    mat-icon {
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  /* should be in companypartners-app-component.scss but only works here */
  .companypartners-content-tabnav-link, .companypartners-sidenav-nav-link {
    .mdc-list-item__primary-text,
    .mdc-tab__text-label {
      color: $dark-primary-text;
      letter-spacing: 0.04em;
      font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    &.active .mdc-list-item__primary-text,
    &.active .mdc-tab__text-label,
    &.active:focus .mdc-tab__text-label {
      font-weight: bold;
    }
  }

  .m-top {
    margin-top: 20px;
  }

  .inline-text {
    height: 17px;
    width: 17px;
    margin: 0 8px;
    vertical-align: baseline;
  }

  .multi-col-form {
    display: flex;
    flex-direction: column;
    .col-50 {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .col-50 + .col-50 {
      margin-top: 20px;
    }
    @media (min-width: $breakpoint-middle) {
      display: block;
      .rowspan {
        width: calc(100% - 14px);
      }
      .half {
        width: calc(50% - 14px);
      }
      .small-input {
        width: 110px;
      }
      .very-small-input {
        width: 80px;
      }
      .rowspan-with-small {
        width: calc(100% - 110px - 28px);
      }
      .rowspan-with-very-small {
        width: calc(100% - 80px - 28px);
      }
      mat-form-field {
        margin-right: 14px;
      }
      .col-50 {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        padding-right: 30px;
      }
      .col-50 + .col-50 {
        margin-top: 0;
      }
    }
  }


  /* Logged out page stuff */
  .tab-content-form {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 100%;
  }

  .card-container {
    .tab-content-form {
      padding-top: 10px;
      margin-bottom: 12px;
      /* I'm sorry. */
      @media (min-width: $breakpoint-tablet) and (min-height: 826px) {
        &.register {
          max-height: calc(100vh - 60px/* header bar */ - 70px/* footer */ - 120px/*margin top*/ - 40px/* card padding */ - 74px/* card header */ - 120px/* description */ - 74px /* rest */);
        }
        &.login {
          max-height: calc(100vh - 60px/* header bar */ - 70px/* footer */ - 120px/*margin top*/ - 40px/* card padding */ - 74px/* card header */ - 74px /* rest */);
        }
      }
    }
  }

  .description {
    margin-bottom: 15px;
  }

  .space-top {
    margin-top: 15px;

    @media (min-width: $breakpoint-tablet) {
      margin-top: 40px;
    }
  }

  .spaced-content {
    margin: 15px 0;
    @media (min-width: $breakpoint-tablet) {
      margin: 40px 0;
    }
  }

  .root {
    padding: $default-page-padding;
    height: 100%;
    overflow: auto;
    &.fixed-header {
      display: grid;
      grid-template-rows: auto 1fr;
      &> *:not(:first-child) {
        min-height: 0;
        overflow: auto;
      }
    }
  }

  mat-button-toggle-group.primary {
    .mat-button-toggle-checked {
      background-color: $primary;
      color: $light-primary-text;
    }
  }

  mat-button-toggle-group.accent {
    .mat-button-toggle-checked {
      background-color: $secondary;
      color: $light-primary-text;
    }
  }

  .reduced-height {
    .mat-button-toggle-label-content {
      line-height: 36px;
    }
  }
}

/****** MATERIAL OVERWRITES ******/

.mat-mdc-unelevated-button.mat-primary:not(:disabled), .mat-mdc-raised-button.mat-primary:not(:disabled) {
  color: $light-primary-text;
}

.padded-card {
  @media (min-width: $breakpoint-tablet) {
    padding: 20px 32px;
  }
}

.padded-actions {
  padding: 24px;
  background: $table-odd;
  gap: 12px;
}

.mat-mdc-card-content {
  min-height: 0;
}

.mat-mdc-card-title {
  h1, h2, h3, h4 {
    margin-top: 0;
  }
}

.mat-mdc-table {
  td {
    vertical-align: middle;
    color: $dark-primary-text;
  }
  th {
    vertical-align: middle;
    @include muted;
    mat-icon {
      vertical-align: bottom;
    }
  }
  &.table-striped {
    tbody {
      tr:nth-child(even) {
        background-color: $background-light;
      }

      tr:nth-child(odd) {
        background-color: $table-odd;
      }
    }
  }
}

.mat-mdc-table .mat-column-action {
  width: 0;
  text-align: center;
}

.mat-mdc-table .mat-column-value, .mat-mdc-table .mat-column-contingent {
  text-align: right;
}

.multi-line-radio {
  .mdc-form-field {
    align-items: start;
    margin-top: 16px;
  }
}

.rowspan {
  width: 100%;
}

.scrollable-block {
  max-height: 500px;
  overflow: auto;
}

label.mdc-label {
  margin-bottom: 0;
}

.ml {
  margin-left: 20px;
}
.ml-4 {
  margin-left: 40px;
}
.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  * {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
