$dark-primary-text: #444444;
$light-primary-text: #FFFFFF;
$swisseldex-primary-palette: (
  50: #e2f3f9,
  100: #b6e1f0,
  200: #89cce6,
  300: #65b9db,
  400: #51abd4,
  500: #459dcd,
  600: #3e8fbf,
  700: #377dac,
  800: #326d98,
  900: #204e79,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$swisseldex-secondary-palette: (
  50: #fffce8,
  100: #fff8c6,
  200: #fff3a0,
  300: #ffee7c,
  400: #fee95f,
  500: #fce445,
  600: #fcd543,
  700: #f9bd3c,
  800: #f6a635,
  900: #ef7f29,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);
$primary: map-get($swisseldex-primary-palette, 400);
$primary-bg: map-get($swisseldex-primary-palette, 50);
$secondary: map-get($swisseldex-secondary-palette, 700);
$background-shaded: #FBFDFE;
$border-shaded: #EDF6FA;
$background-light: #FFFFFF;
$border: #ECECEC;
$muted-text: #999999;
$table-odd: #FAF9F9;

$font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$default-page-padding: 24px;
