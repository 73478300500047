/* ========================================================================
/*! NIFTY ADMIN TEMPLATE V2.9
/* -------------------------------------------------------------------------
/* - ThemeOn.net -
/* =========================================================================
/*
/*
/*
/* IMPORTANT :
/* ===================================================================================================
/* DON'T EDIT ANY CODE HERE, JUST LEAVE IT.
/*
/* You may edit the default color scheme.
/* You may change the variable value inside the _variables.SCSS file and re-compiling using this file.
/*
/* If you make changes directly in the CSS and/ or SCSS-files from Nifty,
/* it will be hard to update if we release an update.
/*
/* So we would recommend to create a new CSS/ SCSS-file for this purpose.
/*
*/





@import "_variables";
$standalone-color-scheme    : false;



// SET AS DEFAULT COLOR SCHEME.
// ============================================
@import "themes/type-a/theme-navy";



@import "nifty/_layout";
@import "nifty/_navbar";
@import "nifty/_mainnav";
@import "nifty/_aside";
@import "nifty/_page-fixedbar";
@import "nifty/_footer";
@import "nifty/_animations";
@import "nifty/_extras";
@import "nifty/_bs-overwrite";
@import "nifty/_helper-classes";

