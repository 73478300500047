// note: this is global style

.showHidePassword {
  position: relative;
  input {
    padding: 6px 30px 6px 12px;
  }
  i {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 99;
    right: 0;
    cursor: pointer;
    height: 100%;
    padding: 10px 10px;
  }
}
