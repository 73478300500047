#content-container:before{
  height: 0;
}
.btn{
  margin: 2px;
}
.form-group {
  min-height: 74px;
  margin-bottom: 10px;
}
.cls-content-register{
  padding: 10px 15px 15px;
}
.help-block {
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: left;
}

.mainnav-sm .navbar-brand img{
  visibility: hidden;
}

.pricing{
  .pricing-price{
    margin: 25px 0 0 0;
  }
  .pricing-explanation{
    margin: 10px 10px 0 10px;
    min-height: 55px;
  }
  .current-pricing {
    .panel-body {
      border: solid 2px #25476a;
      padding: 18px;
    }
    .btn-info{
      visibility: hidden;
    }
  }
  .next-pricing {
    .ribbon-extra span,.ribbon span{
      background: #79af3a;
    }
    .ribbon-extra span:before,.ribbon span:before{
      border-top-color: #79af3a;
      border-left-color: #79af3a;
    }
    .ribbon-extra span:after,.ribbon span:after{
      border-top-color: #79af3a;
      border-right-color: #79af3a;
    }
    .btn-info{
      visibility: hidden;
    }
  }
  .ribbon.ribbon-extra{
    right: -7px;
    width: 170px;
    height: 170px;
    span{
      width: 170px;
      right: -30px;
      top: 40px;
    }
    span:before{
      left: 5px;
    }

  }


  .notavailable-pricing{
    .panel-body {
      background-color: #d2d5dc;
    }
    .btn-info{
      background-color: #8bb0c1;
      border-color: #9ba3a6 !important;
      pointer-events: none;
    }

  }
}


.panel-heading.panel-tabs{
  height: auto;
  padding-bottom: 10px;
  .panel-title{
    line-height: 20px;
    padding: 0 5px 0 5px;
  }
}

.mainnav-widget-content{
  padding-top: 0;
}
.graph-container canvas{
  max-width: 100%;
  padding-bottom: 7px;
  width: 98% !important;
}

.modal-body > .close, .modal-header > .close{
  top: 10px;
  margin-top: 0;
  font-size: 14px;
}
.modal-body {
  padding: 15px 30px 15px;
}
.tab-base .tab-content{
  padding:15px;
}

.modal-content{
  box-shadow: 1px 2px 1px 3px rgba(0, 0, 0, .15);
}

.page-header-main-bckg{
  height: 125px;
  width: 100%;
  /*position: absolute;*/
  background-color: #25476a;
  z-index: 0;
  margin-bottom: -50px;
  * {
    color: #ffffff;
  }
  h3{
    margin: 0;
  }
}

#content-container{
  padding-bottom:25px;
  .mat-drawer-container, .mat-drawer {
    background: transparent;
  }
}


.btn-labeled.fa:before,
.btn-labeled .fa:before{
  font-family: 'Font Awesome 5 Free' !important;
}

#container.mainnav-sm #mainnav-menu > .active-link, #container.mainnav-sm #mainnav-menu > .active-sub{
  padding: 0;
}
@media (max-width: 767px) {
  .navbar-top-links{
    margin-right: 5px;
  }
  .navbar-top-links .tgl-menu-btn{
    margin-left: 5px;
  }
}

@media (min-width: 768px){
  .page-header-main-bckg{
    margin-bottom: -70px;
  }
  .modal-md {
    width: 500px;
  }
  .tab-base .tab-content{
    padding:30px;
  }
  .cls-content-register{
    padding: 50px 15px 15px;
    padding-top: 10vh !important;
  }
  .tab-base .tab-content {
    padding: 20px;
  }
}
.tab-base.no-botton-margin{
  margin-bottom: 0;
}
.tab-base {
  .nav-tabs.tabs-right > li {
    margin-left: 4px
  }

  .nav-tabs>li{
    margin-bottom: 0;
  }

  .nav-tabs{
    .badge{
      padding: 2px 7px;
    }
  }
}

.mainnav-profile {
  overflow: visible;
  .profile-wrap {
    .btn-group {
      margin-top: 10px;
      margin-bottom: 10px;
      a {
        max-width: 170px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .dropdown-menu{
        left: -20px;
      }
    }
  }
}
